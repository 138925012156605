import React from "react";
import Layout from "../../components/layout";
import AboutMenu from "../../components/about/menu";
import H1 from "../../components/h1";
import { companies, types } from "../../components/about/company";
import Seo from "../../components/seo";
import Section from "../../components/section";

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="会社概要" />
      <main>
        <H1>会社概要</H1>
        <AboutMenu />
        <div className="container mx-auto max-w-4xl py-5 md:py-10 px-3 md:px-5">
          <div className="leading-relaxed md:leading-loose text-sm md:text-base mb-5 md:mb-10 p-3 md:p-5">
            2001年にグループ代表の水野真澄がコンサルティング事業を開始してから、一貫して当社の理念となっているのが、「法律根拠」と「実務知識」の双方に基づくコンサルティングサービスの提供です。
            <br />
            海外での事業展開は、言語、法律、制度、商習慣など、ビジネス環境が我が国とは大きく異なるがゆえに容易ではなく、会社内外のトラブルに悩まされる企業は少なくありません。
            <br />
            当社は、日々お寄せいただくクライアント企業様からのご相談に対し、グループ内で蓄積されたリソースだけでなく、現地行政機関からも情報を収集し、最適解のアドバイスを提供してまいりました。
            <br />
            真摯にクライアント企業様と向き合うことで得られた「信頼」と、長年のコンサルティング事業で培った実務知識、法律に対する体系的な理解が、当社の持つ最大の資産となっています。
          </div>
          <div className="grid grid-cols-1 gap-5 md:gap-10">
            {companies.map((company, index) => (
              <div
                className={`flex flex-col md:flex-row justify-between pt-7 md:pt-10 px-3 md:px-5 pb-3 md:pb-5 relative border border-cyan-200 ${
                  index % 2 === 1 ? "bg-cyan-50" : ""
                }`}
                key={index}
              >
                <div
                  className="absolute -top-[1px] -left-[1px]"
                  id="CompanyType"
                >
                  <div
                    className={`${
                      types.find((t) => t.type === company.type).class
                    } text-white text-xs font-sans font-light px-2 py-1`}
                  >
                    {types.find((t) => t.type === company.type).name}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className="md:text-lg pb-3 md:pb-5 mb-1 md:mb-3 border-b border-cyan-800 font-sans"
                    id="CompanyName"
                  >
                    <span>
                      【{company.region}】{company.name}
                    </span>
                    {company.website ? (
                      <a
                        href={company.website}
                        target="_blank"
                        rel="noreferrer"
                        className="ml-5 text-blue-600 hover:text-blue-500 hover:underline"
                      >
                        {company.website}
                      </a>
                    ) : null}
                  </div>
                  <div
                    className="p-3 text-sm md:text-base space-y-1 md:space-y-2"
                    id="CompanyInfo"
                  >
                    <div>{company.address}</div>
                    <div>{company.tel}</div>
                    <div>{company.fax}</div>
                    <div className="space-y-1 md:space-y-2">
                      {company.comment.map((c, index) => (
                        <p key={index}>{c}</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="text-center">{company.image}</div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default AboutPage;
